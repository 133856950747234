<template lang="pug">
    b-container#DeliveryDetailView(fluid="lg")
        div.d-flex.align-items-center(@click="$router.go(-1)")
            span.material-icons.icon(class="icon-atras" ) forward
            p.title-detail-delivery Detalle envío
        b-card(
            bg-variant="light"
            class="shadow card-envios position-relative"
        )
            b-badge(
            :style="getBadgeStyle(delivery.type)"
            class="custom-badge"
            ) {{ delivery.type }}
            p.text-title {{ `#${delivery.numberStore} ${delivery.nameStore}` }}
            p.destinatario-text {{ delivery.finalCustomer }}
            p.text-address {{ delivery.address }}
            p.text-commune {{ delivery.commune }}
            p.text-observation.small-text Observaciones:  {{ delivery.observation ? delivery.observation : '-' }}
            p.text-tracking.small-text N° Seguimiento: {{ delivery.drivInCode }}
            hr(class="hr-custom mt-1")
            b-card-text.card-text-cellphone(v-if="delivery.contactPhone && delivery.status !== 'approved'" )
              span.material-icons.icon(class="icon-cellphone") call
              span.text-phone {{ delivery.contactPhone }}
              font-awesome-icon(:icon="['fab', 'whatsapp']" class="float-right icon-whatsapp" size="2x" @click="openWhatsApp" v-if="delivery.status !== 'approved'")
            hr.hr-custom(v-if="delivery.status === 'approved'")
            p(v-if="delivery.status === 'approved'").text-homologate.small-text {{ delivery.receiverRut }}
            p(v-if="delivery.status === 'approved'").text-homologate.small-text {{ delivery.receiverName }}
            p(v-if="delivery.status === 'approved' && delivery.comentario").text-homologate.small-text Comentario: {{ delivery.comentario }}
            b-row(v-if="delivery.status !== 'approved'")
                b-col
                    b-button(size="sm" class="button-general button-maps w-100 d-flex align-items-center justify-content-center" @click="openMaps('google')")
                      font-awesome-icon(:icon="['fas', 'map-marker-alt']" size="lg" class="mr-2")
                      span Navegar con Maps
                b-col
                    b-button(size="sm" class="button-general button-waze w-100  d-flex align-items-center justify-content-center"  @click="openMaps('waze')")
                      font-awesome-icon(:icon="['fab', 'waze']" size="lg"  class="icon-waze")
                      span Navegar con Waze
            b-button.mt-4(v-if="delivery.status !== 'approved'" class="button-complete text-white w-100 text-center" @click="$router.go(-1)") Completar Entrega
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryDetailView',
  computed: {
    ...mapGetters(['deliverySelected']),
    delivery () {
      return this.deliverySelected
    }
  },
  methods: {
    getBadgeStyle (type) {
      return {
        backgroundColor: type === 'Retiro' ? '#A5DC93' : '#4D9A97',
        color: type === 'Retiro' ? '#4F4F4F' : 'white'
      }
    },
    openMaps (app) {
      const address = encodeURIComponent(this.delivery.address)
      let url
      if (app === 'google') {
        url = `https://www.google.com/maps/search/?api=1&query=${address}`
      } else if (app === 'waze') {
        url = `https://waze.com/ul?q=${address}`
      }
      window.open(url, '_blank')
    },
    openWhatsApp () {
      let phoneNumber = this.delivery.contactPhone.replace(/\s+/g, '')
      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.substring(1)
      }
      const fullNumber = `56${phoneNumber}`
      const url = `https://wa.me/${fullNumber}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.title-detail-delivery{
  color: #4F4F4F;
  font-size: 21px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  margin-top: 19px;
}
.icon-atras{
  transform: rotate(180deg);
  color:#264848;
  width: 24px;
  height: 24px;
  margin-right: 5px;

}
.card-text-cellphone {
  display: flex;
  align-items: center;
}

.text-phone {
  margin-right: 8px;
  color: #F7B232;
}

.icon-whatsapp {
  margin-left: auto;
  color: #4D9A97;
}
.hr-custom {
  margin-bottom: 8px;
  border: 1px solid #F8F7F7
}
.icon-cellphone {
  color: #F7B232;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.icon-waze {
  margin-right: 0.3rem;
}

</style>
